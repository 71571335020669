.homepage {
	#header {
		left: 0;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 9999;
	}
}

#header {
	padding: 15px 0;
	
	@include media ('>=tablet') {
		padding: 32px 0;
	}
	
	.container {
		align-items: center;
		display: flex;
		justify-content: space-between;
		max-width: none;
		
		@include media ('>=desktop') {
			padding: 0 40px;
		}
	}
	
	.btn {
		border-radius: 6px;
		font-size: 13px;
		font-family: $jet-brains-mono-font;
		min-width: 75px;
		padding: 4px;
	}
}

.logo {
	display: block;
	margin-right: 20px;
	width: 150px;
	
	a {
		display: block;
	}
}