#wrapper {
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	flex: 1 1 auto;
}

.resize-active {
	* {
		transition: none !important;
	}
}

#main {
	flex: 1 1 auto;
}

body {
	min-height: 100vh;
	display: flex;
}

body {
	@include media ('>=desktop') {
		font-size: 21px;
		line-height: 1.52;
	}
}

input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
	display: none;
}

::-ms-clear {
	display: none;
}

a {
	@include animate(color);
	
	font-weight: 700;
	text-decoration: none;
	
	&:hover {
		text-decoration: underline;
	}
}

h1, .h1 {
	line-height: 1.19;
	font-weight: 900;
	
	@include media ('>=tablet') {
		font-size: 38px;
	}
	
	@include media ('>=desktop') {
		font-size: 54px;
	}
}

.container {
	margin: 0 auto;
	max-width: 1230px;
	padding: 0 15px;
}

.btn {
	@include animate(background-color color border-color);
	
	background-color: $btn-background;
	border: 2px solid $btn-background;
	border-radius: 10px;
	color: $btn-color;
	display: inline-block;
	font-size: $btn-font-size;
	font-weight: $btn-font-weight;
	min-width: 188px;
	padding: $btn-padding;
	text-align: center;
	vertical-align: top;
	
	@include media ('>=tablet') {
		font-size: $btn-font-size-md;
	}
	
	&:hover {
		background-color: $btn-color;
		color: $btn-background;
		text-decoration: none;
	}
	
	&.btn-outline {
		border-color: $very-light-gray;
		background-color: transparent;
		color: $base-text-color;
		
		@include media ('>=phone') {
			min-width: 152px;
		}
		
		&:hover {
			background-color: transparent;
			color: $btn-background;
			border-color: $btn-background;
		}
	}
	
	&.switch-btn {
		border-radius: 11px;
		background-color: transparent;
		border-color: transparent;
		color: $dark-gray;
		min-width: auto;
		padding: 10px;
		width: 50%;
		
		&.without {
			background-color: $white;
		}
		
		&:hover {
			background-color: $blue;
			color: $btn-color;
		}
	}
	
	&.btn-light {
		background-color: $white;
		border-color: $white;
		color: $btn-background;
		
		&:hover {
			background-color: transparent;
			color: $white;
		}
	}
	
	&[disabled] {
		background-color: rgba($black, .1);
		border: none;
		cursor: default;
		padding-bottom: 15px;
		padding-top: 15px;
		
		&:hover {
			color: $btn-color;
		}
	}
	
	&.btn-back,
	&.btn-next {
		min-width: 100px;
	}
	
	&.btn-back {
		background-color: $off-white;
		border-color: $off-white;
		color: $mid-gray;
		
		&:hover {
			&:hover {
				background-color: $btn-background;
				border-color: $btn-background;
				color: $btn-color;
			}
		}
	}
	
	&.btn-loading {
		color: $btn-background;
		background-color: rgba($btn-background, .08);
		border: none;
		min-width: 167px;
		padding-bottom: 15px;
		padding-top: 15px;
		
		&:hover {
			color: $btn-background;
			background-color: rgba($btn-background, .08);
		}
	}
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
input[type='date']
textarea {
	@include animate(border-color);
	
	border-radius: $form-element-border-radius;
	height: $input-height;
	width: 100%;
	
	@include media ('>=desktop') {
		height: $input-height-lg;
	}
}

.intro-section {
	background-image: linear-gradient(299deg, $off-white 100%, rgba($off-white, 0) 7%);
	position: relative;
	
	@include media ('<tablet') {
		padding-top: 65px;
	}
	
	@include media ('>=tablet') {
		min-height: 600px;
	}
	
	@include media ('>=desktop') {
		min-height: 100vh;
	}
	
	&:before {
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0), $black);
		bottom: 0;
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		opacity: .03;
		transform-origin: 100% 100%;
		transform: rotate(20deg);
	}
	
	.container {
		display: flex;
		max-width: 1640px;
	}
	
	.btn-holder {
		margin-top: 32px;
		text-align: center;
		
		@include media ('<phone') {
			width: 100%;
		}
	}
}

.flex-holder {
	padding-top: 40px;
	width: 100%;
	
	@include media ('>=tablet') {
		align-items: flex-start;
		flex-direction: column;
		display: flex;
		justify-content: center;
		min-height: 600px;
		padding-top: 85px;
	}
	
	@include media ('>=desktop') {
		min-height: 100vh;
	}
}

.heading-holder {
	width: 100%;
	
	@include media ('>=tablet') {
		align-items: center;
		display: flex;
	}
	
	.heading {
		@include media ('>=tablet') {
			padding-right: 30px;
			width: 58%;
		}
		
		@include media ('>=1808px') {
			padding-left: 204px;
		}
	}
	
	.img-holder {
		@include media ('>=tablet') {
			width: 42%;
		}
		
		@include media ('>=widescreen') {
			margin-bottom: 18px;
		}
	}
}

.btn-holder {
	@include media ('>=phone') {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		text-align: left;
	}
	
	.btn {
		margin-bottom: 20px;
		
		@include media ('>=phone') {
			margin: 0 10px 20px;
		}
	}
}

.heading {
	h1 {
		margin-bottom: 16px;
	}
	
	p {
		margin-bottom: 32px;
	}
}

.subtitle {
	color: $blue;
	display: block;
	font-size: 17px;
	font-family: $jet-brains-mono-font;
	line-height: 1.41;
	margin-bottom: 11px;
	text-transform: uppercase;
}

.description-section {
	padding: 50px 0;
	
	@include media ('>=desktop') {
		padding: 70px 0;
	}
	
	@include media ('>=widescreen') {
		padding: 168px 0 65px;
	}
	
	.container {
		@include media ('>=tablet') {
			align-items: flex-start;
			display: flex;
		}
	}
	
	.text-holder {
		@include media ('>=tablet') {
			order: 2;
			padding-left: 24px;
			width: 50%;
		}
		
		p {
			margin-bottom: 24px;
		}
	}
}

.switcher-holder {
	@include media ('>=tablet') {
		order: 1;
		width: 50%;
	}
	
	&.with-active {
		.period-list {
			&.list-with {
				display: block;
			}

			&.list-without {
				display: block;
			}
		}
		
		.switch-btn {
			&:hover {
				background-color: $btn-background;
				color: $btn-color;
			}
			
			&.without {
				background-color: transparent;
				
				&:hover {
					background-color: $btn-background;
					color: $btn-color;
				}
			}
			
			&.with {
				background-color: $btn-background;
				color: $btn-color;
			}
		}
	}
}

.switcher {
	align-items: center;
	background-color: $off-white;
	border-radius: 16px;
	display: flex;
	max-width: 472px;
	margin-bottom: 36px;
	padding: 7px;
	
	@include media ('>=tablet') {
		margin-bottom: 56px;
	}
}

.period-list {
	@extend %listreset;
	
	&.list-with {
		display: none;
	}
	
	li {
		align-items: center;
		color: $mid-gray;
		display: flex;
		font-size: 15px;
		font-family: $jet-brains-mono-font;
		padding: 20px 0;
		position: relative;
		
		@include media ('>=desktop') {
			font-size: 17px;
		}
		
		&:after {
			border: 1px dashed $light-purple;
			bottom: 0;
			content: '';
			position: absolute;
			left: 15px;
			top: 3px;
			width: 1px;
		}
		
		&.no {
			color: $light-red;
			position: relative;
			
			&:after {
				border-color: $light-red;
			}
			
			.title {
				color: $red;
				text-decoration: line-through;
			}
		}
		
		.title {
			color: $dark-gray;
			display: block;
			line-height: 1;
			margin-bottom: 2px;
		}
		
		.text {
			padding-left: 28px;
			width: calc(100% - 40px);
		}
	}
}

.icon {
	@include size(32px);
	
	align-items: center;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	position: relative;
	z-index: 1;
	
	&:after,
	&:before {
		background-color: $white;
		border-radius: 1.5px;
		content: '';
		height: 15px;
		left: 50%;
		position: absolute;
		top: 50%;
		width: 3px;
	}
	
	&:before {
		transform: translate(-50%, -50%) rotate(45deg);
	}
	
	&:after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
	
	&.tick {
		&:before {
			background-color: transparent;
			border: 3px solid $white;
			border-width: 0 0 3px 3px;
			content:'';
			height: 8px;
			margin: -5px 0 0 -7px;
			transform: rotate(-45deg);
			width: 15px;
		}
		
		&.green {
			background-color: $green;
			
			&:after {
				display: none;
			}
		}
	}
	
	&.green {
		background-color: $green;
	}
	
	&.red {
		background-color: $red;
	}
	
	&.blue-border {
		background-color: $blue;
		
		&:after {
			border: 5px solid rgba($blue, .2);
			border-radius: 50%;
			bottom: -5px;
			background-color: transparent;
			left: -5px;
			height: auto;
			right: -5px;
			transform: none;
			top: -5px;
			width: auto;
		}
	}
}

.testimonials {
	@include media ('>=widescreen') {
		padding: 65px 0;
	}
}

blockquote {
	background-color: $off-white;
	display: flex;
	margin: 0;
	padding: 20px 20px 80px;
	
	@include media ('>=tablet') {
		padding: 40px 40px 80px;
	}
	
	@include media ('>=desktop') {
		padding: 80px 80px 178px;
	}
	
	.photo-holder {
		@include size(80px);
		
		position: relative;
		
		@include media ('>=tablet') {
			@include size(112px);
		}
	}
	
	.photo {
		background-color: $light-purple;
	}
	
	.photo,
	.brand-img {
		border-radius: 50%;
		height: 100%;
		overflow: hidden;
		width: 100%;
	}
	
	.brand-img {
		@include size(38px);
		
		background-color: $white;
		bottom: 0;
		right: 0;
		position: absolute;
		
		&:after {
			border-radius: 50%;
			border: 5px solid $white;
			bottom: -1px;
			content: '';
			left: -1px;
			position: absolute;
			right: -1px;
			top: -1px;
		}
	}
	
	.text {
		padding-left: 15px;
		padding-top: 7px;
		width: calc(100% - 80px);
		
		@include media ('>=tablet') {
			padding-left: 48px;
			width: calc(100% - 112px);
		}
		
		p {
			@include media ('>=tablet') {
				margin-bottom: 4px;
			}
			
			&:after {
				content: '\201C';
			}
			
			&:before {
				content: '\201D';
			}
		}
	}
	
	cite {
		color: $blue;
		font-family: $jet-brains-mono-font;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		
		@include media ('>=tablet') {
			font-size: 17px;
		}
	}
}

.rating-block {
	background-color: $white;
	box-shadow: 0 16px 48px 0 rgba(75, 81, 185, 0.05);
	margin: -57px 20px 0;
	padding: 5px 15px;
	position: relative;
	text-align: center;
	
	@include media ('>=tablet') {
		padding: 15px;
		margin: -67px 40px 0;
	}
	
	@include media ('>=desktop') {
		margin: -96px 80px 0;
		padding: 42px;
	}
	
	.link {
		color: $light-gray;
		font-size: 16px;
		right: 23px;
		position: absolute;
		top: 18px;
		
		&:hover {
			color: $blue;
			text-decoration: none;
		}
	}
}

.article-section {
	padding: 50px 0;
	text-align: center;
	
	@include media ('>=desktop') {
		padding: 70px 0;
	}
	
	@include media ('>=widescreen') {
		padding: 90px 0 168px;
	}
	
	.logo-holder {
		@include size(63px);
		
		margin: 0 auto 31px;
		
		img {
			width: 100%;
		}
	}
	
	.text {
		margin: 0 auto;
		max-width: 780px;
	}
	
	.btn {
		&.btn-outline {
			min-width: 280px;
		}
		
		.icon-new-window {
			font-size: 15px;
			padding-left: 10px;
		}
	}
}

.get-quote {
	background-color: #455eff;
	color: $white;
	padding: 50px 0;
	overflow: hidden;
	position: relative;
	
	@include media ('>=desktop') {
		padding: 70px 0;
	}
	
	@include media ('>=widescreen') {
		padding: 168px 0 160px;
	}
	
	&:before,
	&:after {
		bottom: 0;
		content: "";
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
	
	&:before {
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0), $black);
		opacity: .05;
		transform-origin: 100% 100%;
		transform: rotate(20deg);
	}
	
	&:after {
		background-image: url("../../images/platforms-graphic-footer.svg");
		background-position: 106% 50%;
		background-repeat: no-repeat;
		content: "";
	}
	
	.container {
		position: relative;
		z-index: 1;
	}
	
	.heading {
		max-width: 900px;
	}
	
	h1 {
		color: $white;
	}
}

.form-section {
	padding-bottom: 50px;
	
	@include media ('>=tablet') {
		padding-bottom: 80px;
	}
	
	@include media ('>=widescreen') {
		padding-bottom: 160px;
	}
	
	.container {
		max-width: 606px;
	}
	
	.heading {
		p {
			margin-bottom: 34px;
		}
	}
	
	.subtitle {
		text-transform: none;
	}
	
	.btn-holder {
		display: flex;
		justify-content: space-between;
		
		.btn-next {
			margin-left: auto;
		}
	}
	
	.brand-img {
		@include size(48px);
		
		border-radius: 11px;
		display: block;
	}
	
	.form-message {
		.heading {
			p {
				margin-bottom: 24px;
			}
		}
		
		.btn-holder {
			margin-top: 34px;
		}
	}
}

.step-form {
	padding-top: 50px;
	
	@include media ('>=tablet') {
		padding-top: 80px;
	}
}

.step-1 {
	.btn-back {
		display: none;
	}
}

input[type='radio'],
input[type='checkbox'] {
	position:fixed;
	left:0;
	top:0;
	opacity:0;
	z-index: -1;
	
	&:checked {
		~ .fake-label {
			border-color: $blue;
		}
	}
	
	&.date-radio {
		&:checked {
			~ .fake-label {
				border-color: rgba($blue, 0);
				background-color: rgba($blue, .08);
				color: $blue;
			}
		}
	}
}

.form-row {
	@include media ('>=phone') {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -12px;
	}
	
	.col-6 {
		@include media ('>=phone') {
			padding: 0 12px;
			width: 50%;
		}
	}
}

.radio-label {
	display: block;
	margin-bottom: 24px;
	
	&.lg-label {
		margin-bottom: 14px;
		
		.img-holder {
			position: relative;
			
			.brand-img {
				@include media ('>=tablet') {
					@include size(56px);
				}
			}
			
			.tick-img {
				@include size(20px);
				
				position: absolute;
				right: -8px;
				top: -8px;
			}
		}
		
		.fake-label {
			@include media ('>=tablet') {
				padding: 30px;
			}
			
			.text {
				width: calc(100% - 78px);
				
				@include media ('<tablet') {
					padding-left: 10px;
				}
				
				@include media ('>=tablet') {
					width: calc(100% - 150px);
				}
			}
		}
	}
}

.dashed-line {
	border: 1px dashed $very-light-gray;
	margin-left: 10px;
	width: 20px;
	
	@include media ('>=tablet') {
		margin-left: 20px;
		width: 50px;
	}
}

.fake-label {
	@include animate(border-color);
	
	align-items: center;
	cursor: pointer;
	border: 2px solid $form-element-border-color;
	border-radius: $form-element-border-radius;
	padding: 20px;
	display: flex;
	
	.text {
		padding-left: 20px;
		width: calc(100% - 48px);
		
		&:only-child {
			@include animate(color);
			
			padding-left: 0;
			text-align: center;
			width: 100%;
		}
	}
	
	.title {
		display: block;
		font-size: 21px;
		margin-bottom: 5px;
		
		@include media ('>=tablet') {
			line-height: 1;
			margin-bottom: 0;
		}
	}
	
	.category {
		color: $light-gray;
		font-size: 17px;
		font-family: $jet-brains-mono-font;
	}
}

.input-holder {
	margin-bottom: 24px;
	position: relative;
	
	&:last-of-type {
		margin-bottom: 15px;
	}
	
	&.with-img {
		.brand-img {
			@include size(30px);
			
			left: 24px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			
			@include media ('>=desktop') {
				@include size(40px);
			}
		}
		
		input[type='text'] {
			padding-left: 68px;
			
			@include media ('>=desktop') {
				padding-left: 78px;
			}
		}
	}
	
	&.date {
		margin-bottom: 17px;
		
		input[type='text'] {
			padding-right: 50px;
		}
		
		.icon-date-picker {
			color: $placeholder-color;
			right: 24px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.message {
	align-items: center;
	color: $mid-gray;
	display: flex;
	font-size: 15px;
	margin-bottom: 32px;
	
	@include media ('>=tablet') {
		font-size: 17px;
	}
	
	.icon-secure {
		color: $green;
		font-size: 20px;
		padding-right: 10px;
	}
	
	a {
		color: inherit;
		font-weight: 400;
		padding-left: 4px;
		text-decoration: underline;
		
		&:hover {
			color: $blue;
		}
	}
}

.date-value {
	font-size: 17px;
	font-weight: 500;
	padding-left: 4px;
}

.step-3 {
	&.vin {
		.input-holder {
			margin-bottom: 32px;
		}
	}
}

.label-holder {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	margin: 0 -6px;
	
	.radio-label {
		padding: 0 6px 12px;
		margin-bottom: 16px;
		width: 50%;
		
		@include media ('>=phone') {
			padding: 0 6px;
			width: 25%;
		}
		
		.fake-label {
			border-radius: 10px;
			padding: 10px;
			
			@include media ('>=tablet') {
				padding: 17px;
			}
			
			.text {
				.title {
					font-size: 17px;
					font-weight: 500;
				}
			}
		}
	}
}

.ui-widget-header,
.ui-widget-content {
	@include animate(border-color background-color color);
	
	color: $base-text-color;
}

.ui-widget-header {
	background-color: $off-white;
	
	.ui-icon {
		background: none;
	}
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	@include animate(border-color background-color color);
	
	background-color: $off-white;
	border-color: $light-gray;
	color: $base-text-color;
	border-radius: 4px;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	background-color: $blue;
	border-color: $blue;
	color: $white;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	top: auto;
	
	span {
		&:before {
			font-family: 'icomoon' !important;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.ui-datepicker .ui-datepicker-prev {
	span {
		&:before {
			content: "\e925";
		}
	}
}

.ui-datepicker .ui-datepicker-next {
	span {
		&:before {
			content: "\e926";
		}
	}
}

.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui {
	border: 1px solid $light-gray;
	background: rgba($blue, .08);
	font-weight: normal;
	color: $mid-gray;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
	color:#2b2b2b;
	text-decoration:none
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
	border: 1px solid $blue;
	background: $blue;
	font-weight: normal;
	color: $white;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
	border: 1px solid $blue;
	background: rgba($blue, .08);
	font-weight: normal;
	color: $blue;
}

span {
	&.btn {
		&.btn-outline {
			margin-bottom: 16px;
			width: 100%;
			
			&:hover {
				border-color: $very-light-gray;
				background-color: transparent;
				color: $base-text-color;
			}
		}
	}
}

.color {
	bottom: -2px;
	border-radius: 12px 0 0 12px;
	display: block;
	left: -2px;
	position: absolute;
	top: -2px;
	width: 32px;
	z-index: 1;
	
	&.silver {
		background-color: #ced4e0;
	}
	
	&.gold {
		background-color: #f7dd63;
	}
	
	&.platinum {
		background-color: #8b9bff;
	}
	
	&.diamond {
		background-color: $very-dark-gray;
	}
}

.step-5 {
	input[type='radio'] {
		&:checked {
			~ .fake-label {
				border-color: $form-element-border-color;
				
				&:after {
					opacity: 1;
				}
			}
		}
	}
	
	.radio-label {
		&:last-of-type {
			margin-bottom: 34px;
		}
	}
	
	.fake-label {
		padding-left: 62px;
		padding: 30px 20px 30px 50px;
		position: relative;
		
		@include media ('>=tablet') {
			padding: 30px 20px 30px 62px;
		}
		
		&:after {
			@include animate(opacity);
			
			border: 3px solid $blue;
			border-radius: 14px;
			bottom: -6px;
			content: '';
			left: -6px;
			opacity: 0;
			position: absolute;
			right: -6px;
			top: -6px;
		}
		
		.text {
			padding-left: 0;
			width: 100%;
		}
		
		.description {
			display: block;
			font-size: 17px;
			margin-top: 10px;
		}
	}
}

.form-message {
	padding-top: 32px;
	
	.img-holder {
		margin-bottom: 32px;
		
		@include media ('>=tablet') {
			margin: 0 -10px 64px;
		}
		
		img {
			width: 100%;
		}
	}
	
	a {
		&:not(.btn) {
			font-weight: 400;
			text-decoration: underline;
			
			&:hover {
				text-decoration: none;
			}
		}
	}
	
	.btn {
		min-width: 225px;
	}
}
