#footer {
	background-color: $off-white;
	padding: 15px 0;

	@include media ('>=tablet') {
		padding: 33px 0;
	}

	.container {
		@include media ('>=tablet') {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}
	}

	a {
		text-decoration: underline;

		&:hover {
			color: $base-link-color;
		}
	}

	a,
	.copyright {
		color: $mid-gray;
		font-size: 15px;
		font-weight: 400;
	}

	.copyright {
		a {
			text-decoration: none;
		}
	}
}

.footer-list {
	@extend %listreset;

	@include media ('>=tablet') {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	li {
		padding: 0 12px 12px 0;

		@include media ('>=tablet') {
			padding-bottom: 0;
		}

		&:last-child {
			margin: 0 0 0 auto;
			padding-right: 0;
		}
	}
}