@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?yrjyc3');
  src:  url('../../fonts/icomoon.eot?yrjyc3#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?yrjyc3') format('truetype'),
    url('../../fonts/icomoon.woff?yrjyc3') format('woff'),
    url('../../fonts/icomoon.svg?yrjyc3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-date-picker:before {
  content: "\e900";
}
.icon-secure:before {
  content: "\e901";
}
.icon-new-window:before {
  content: "\e902";
}
.icon-chevron-left:before {
  content: "\e925";
}
.icon-chevron-right:before {
  content: "\e926";
}
