$breakpoints: (
	'xs-phone': 320px,
	'phone': 480px,
	'tablet': 768px,
	'desktop': 1024px,
	'widescreen': 1200px
);

// keywords
$media-expressions: (
	'screen': 'screen',
	'print': 'print',
	'handheld': 'handheld',
	'landscape': '(orientation: landscape)',
	'portrait': '(orientation: portrait)',
	'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
	'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

#root {
	width: 100%;
}

// ligatured operators ≥ ≤

@import "~react-datepicker/dist/react-datepicker.css";

@import 'vendors/include-media';
@import 'vendors/normalize';
@import 'vendors/fonts';
@import 'vendors/icomoon';
//@import 'vendors/datepicker';

@import 'base/functions';
@import 'base/variables';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
@import 'base/typography';
@import 'base/forms';

@import 'layout/header';
@import 'layout/common';
@import 'layout/footer';

.loader {
	width: 100%;
	margin: 0 auto;
	&__img-wrapper {
		width: 100px;
		text-align: center;
		margin: 0 auto;
		&__img {

		}
	}

	&__text {
		text-align: center;
		width: 500px;
		margin: 0 auto;
		display: block;
		margin-top: 20px;
	}
}
