// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$off-white: #f7f7fc;
$light-gray: #b7b8c9;
$very-light-gray: #e0e0ec;
$gray: #999;
$mid-gray: #767690;
$dark-gray: #4c4e6e;
$very-dark-gray: #0e203e;
$black: #000;
$light-purple: #e1e1fe;
$white: #fff;
$blue: #4765fb;
$green: #29e310;
$light-red: #f8977e;
$red: #ff572a;

$base-font-sans-serif: 'Roboto', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$jet-brains-mono-font: 'JetBrainsMono', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $dark-gray;
$base-background-color: $white !default;
$font-size-base: 16px !default;
$line-height-base: 1.2 !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $blue;

// Buttons 
$btn-color: $white;
$btn-background: $blue;
$btn-padding: 13px 10px;
$btn-font-size: 15px;
$btn-font-size-md: 17px;
$btn-font-weight: 700;

// Forms
$form-element-padding: 12px 24px;
$form-element-padding-md: 24px;
$form-element-border-color: $very-light-gray;
$form-element-focus-border-color: $blue;
$form-element-border-radius: 12px;
$placeholder-color: rgba($dark-gray, .4);
$input-color: $dark-gray;
$input-height: 50px;
$input-height-lg: 72px;

// Headers
$h1-font-size: 30px !default;
$h2-font-size: 27px !default;
$h3-font-size: 24px !default;
$h4-font-size: 21px !default;
$h5-font-size: 17px !default;
$h6-font-size: 15px !default;

$headings-font-family: inherit;
$headings-color: $very-dark-gray;


$animation-speed: 0.3s;
